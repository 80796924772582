/* src/components/Footer.css */
.footer {
  background-color: #357ae8;
  padding: 10px;
  text-align: center;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* src/components/PriceList.css */
.price-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  flex: 1; /* Ensures the price list takes the remaining width */
}

.filters {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to fit nicely */
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center; /* Center align the filters */
  align-items: center; /* Vertically center the items */
}

.search-input, .price-input, .rarity-input, .type-dropdown {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.price-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}

.pagination-button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #357ae8;
  color: white;
  cursor: pointer;
}

.pagination-button.active {
  font-weight: bold;
  background-color: #1ca3d0;
}

/* New styles for the Calculate Prices button */
.calculate-button {
  padding: 10px 20px;
  background-color: #357ae8;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px; /* Ensure some space above the button */
  align-self: center; /* Center the button horizontally */
}

.calculate-button:hover {
  background-color: #1ca3d0;
}

/* src/components/PriceList.css */
@media (max-width: 768px) {
  .filters {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .price-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .price-item {
    width: 45%; /* Ensure two items per row */
    margin: 2.5%; /* Add margin for spacing */
  }
}

/* src/components/News.css */
.news {
  width: 10%;
  padding: 20px;
  border-right: 1px solid #ccc;
}

.news h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.news ul {
  list-style: none;
  padding: 0;
}

.news li {
  margin-bottom: 10px;
}

.news-date {
  font-weight: bold;
}

.news-text {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .news {
    width: 90%;
    margin-bottom: 20px;
  }
}

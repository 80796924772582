/* src/App.css */
.App {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.main-content {
  display: flex;
  width: 100%;
  padding: 20px;
  flex: 1;
  overflow: auto; /* Enable vertical scrolling */
  margin-bottom: 60px; /* Ensure space for the footer */
  justify-content: space-between; /* Ensure consistent spacing */
}

.footer {
  background-color: #357ae8;
  padding: 10px;
  text-align: center;
  color: white;
  width: 100%;
  position: fixed; /* Keep the footer fixed at the bottom */
  bottom: 0;
}

.auth-button {
  padding: 10px;
  background-color: rgb(24, 208, 24);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  box-sizing: border-box;
}

.auth-button:hover {
  background-color: rgb(8, 74, 8);
}

.google-logo {
  width: 20px; /* Adjust the size as needed */
  height: 20px;
  margin-right: 10px; /* Space between logo and text */
}

/* New styles for sign-out button */
.signout-button {
  background-color: rgb(208, 24, 24); /* Different background color for sign-out */
}

.signout-button:hover {
  background-color: rgb(148, 16, 16); /* Different hover color for sign-out */
}

.news {
  background-color: aqua;
  width: 10%; /* Adjusted width */
}

/* src/App.css */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    align-items: center;
  }

  .news {
    width: 90%; /* Make news section take full width on mobile */
    margin-bottom: 20px;
  }

  .price-list-container {
    width: 100%;
  }
}


/* src/components/PriceItem.css */
.price-item {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  width: 200px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.price-item-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.price-item-details {
  margin-top: 10px;
}

.item-price-credits {
  font-size: 16px;
  margin-bottom: 10px;
}

.item-price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.small-icon {
  width: 30px;
  height: 30px;
}

.vote-button {
  margin-top: 10px;
  padding: 10px;
  background-color: #357ae8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.vote-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.vote-button:hover {
  background-color: #1ca3d0;
}

.suggestion-fields {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.suggestion-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.submit-suggestion-button {
  padding: 10px;
  background-color: #357ae8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-suggestion-button:hover {
  background-color: #1ca3d0;
}

/* src/components/PriceItem.css */
@media (max-width: 768px) {
  .price-item {
    width: 45%; /* Ensure two items per row */
    margin: 2.5%; /* Add margin for spacing */
    box-sizing: border-box;
  }

  .price-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}

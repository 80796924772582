/* src/components/Header.css */
.header {
  background-color: #357ae8;
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 24px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.habbo-character {
  width: 80px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between image and text */
}

@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Stack elements vertically on small screens */
  }

  .habbo-character {
    margin-right: 0; /* Reset margin */
  }
}

/* src/components/PriceComparisonModal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
  text-align: center;
}

.item-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.calculate-button,
.close-button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: red;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 10px;
  
}

.close-button:hover {
  background-color: darkred;
}

.results {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}
